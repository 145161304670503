import React from "react";
import { AppBar, Box, Button, Grid, Hidden, Stack, Toolbar } from "@mui/material";
import { NavHashLink } from "react-router-hash-link";
import { INavigationLink } from "../../../types/nav";

import HamburguerMenu from "./HamburguerMenu";
import LanguageButton from "./LanguageButton";

import colors from "../../../themes/colors";
import GridContainer from "../../../components/GridContainer";
import LinkButton from "../../../components/LinkButton";
import Translate from "../../../components/Translate";
// eslint-disable-next-line import/order
import { useLocation } from "react-router-dom";

interface NavBarProps {
  links: INavigationLink[];
  buttonNav?: string; // Novo campo para o link do botão
  hideFreeTrialButtonNav?: boolean;
  hideTranslateButton?: boolean;
  desktopLogo?: string;
  mobileLogo?: string;
}

export default function NavBar({
  links,
  buttonNav,
  hideFreeTrialButtonNav,
  hideTranslateButton,
  desktopLogo = "/assets/bizpik-logo.svg", // Padrão
  mobileLogo = "/assets/bizpik-icon-logo.svg" // Padrão
}: NavBarProps) {
  const router = useLocation();

  return (
    <AppBar
      component="nav"
      elevation={0}
      classes={{ positionSticky: "glass-background" }}
      position="sticky"
    >
      <Toolbar className="h-16 md:h-[10rem]" disableGutters>
        <GridContainer childrenProps={{ muiGrid: { className: "py-0" } }}>
          <Grid
            container
            item
            xs={12}
            sm={10}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
          >
            <NavHashLink to="/">
              <Hidden mdDown>
                <img className="h-20" src={desktopLogo} alt="bizpik-logo" />
              </Hidden>
              <Hidden mdUp>
                <img className="h-12" src={mobileLogo} alt="bizpik-logo" />
              </Hidden>
            </NavHashLink>

            <Stack
              className="mx-8"
              direction="row"
              spacing={4}
              alignItems="center"
              flexWrap="wrap"
            >
              <Hidden mdDown>
                {links.map(link =>
                  link.hiddenOnDesktop ? null : (
                    <LinkButton
                      key={link.anchor}
                      children={<Translate customKey={link.label} />}
                      anchor={
                        !link.anchor || link.anchor.startsWith("#")
                          ? link.anchor
                          : `#${link.anchor}`
                      }
                      href={link.path}
                    />
                  )
                )}
              </Hidden>
              <Box>
                {/* Aqui o botão será ocultado se hideFreeTrialButtonNav for true */}
                {!hideFreeTrialButtonNav && (
                  <Button
                    href={
                      buttonNav ||
                      `https://register.bizpik.com.br/company?plan=START&${
                        router.pathname ? `partner=${router.pathname.slice(1)}` : ""
                      }`
                    }
                    sx={{
                      backgroundColor: colors.violet,
                      textTransform: "none",
                      fontSize: 14,
                      lineHeight: "22px",
                      height: "auto",
                      ":hover": {
                        backgroundColor: colors.violetDarker
                      }
                    }}
                    className="py-1.5"
                    variant="contained"
                  >
                    <Translate i18nKey="label:test" />
                  </Button>
                )}
              </Box>
            </Stack>

            <Box className="flex items-center">
              {!hideTranslateButton && <LanguageButton />}

              <Hidden mdDown>
                <Button
                  href="https://sistema.bizpik.com.br"
                  sx={{
                    backgroundColor: colors.dodgerBlue,
                    textTransform: "none",
                    lineHeight: "22px",
                    fontSize: 18,

                    ":hover": {
                      backgroundColor: colors.dodgeBlueDarker
                    }
                  }}
                  className="font-semibold py-2 px-3 ml-6 lg:p-4 lg:px-6 lg:ml-8"
                  variant="contained"
                  children={<Translate i18nKey="label:login" />}
                />
              </Hidden>

              <Hidden mdUp>
                <HamburguerMenu links={links} />
              </Hidden>
            </Box>
          </Grid>
        </GridContainer>
      </Toolbar>
    </AppBar>
  );
}
