import React from "react";

interface WhatsAppButtonProps {
  linkWhatsApp?: string; // Link do WhatsApp
  backgroundColor?: string; // Cor do botão
  position?: { bottom: string; right: string }; // Posição do botão
}

function WhatsAppButton({
  linkWhatsApp = "https://wa.me/5511978791500",
  backgroundColor = "#25D366",
  position = { bottom: "20px", right: "20px" }
}: WhatsAppButtonProps) {
  return (
    <a
      href={linkWhatsApp}
      rel="noopener noreferrer"
      target="_blank"
      style={{
        position: "fixed",
        bottom: position.bottom,
        right: position.right,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "60px",
        height: "60px",
        backgroundColor,
        borderRadius: "50%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
        zIndex: 1000,
        transition: "opacity 0.3s",
        opacity: 1
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="white"
      >
        <path d="M20.52 3.48A11.787 11.787 0 0 0 12 0C5.372 0 0 5.372 0 12c0 2.103.547 4.16 1.578 5.968L0 24l5.945-1.563A11.931 11.931 0 0 0 12 24c6.627 0 12-5.373 12-12 0-3.202-1.252-6.21-3.48-8.52zM12 22.188c-2.109 0-4.105-.577-5.863-1.664l-.418-.25-3.531.922.957-3.461-.27-.431C2.205 16.1 1.688 14.088 1.688 12 1.688 6.52 6.52 1.688 12 1.688 17.48 1.688 22.312 6.52 22.312 12c0 5.479-4.832 10.312-10.312 10.312zm5.76-7.834c-.322-.161-1.91-.941-2.208-1.047-.297-.109-.516-.161-.734.161-.215.322-.841 1.046-1.031 1.261-.19.215-.381.242-.703.08-.322-.161-1.355-.5-2.582-1.596-.957-.854-1.598-1.91-1.785-2.23-.187-.322-.02-.497.141-.658.146-.147.322-.381.483-.572.163-.191.216-.323.322-.537.108-.215.054-.403-.027-.572-.08-.161-.733-1.763-1.005-2.42-.267-.643-.537-.557-.734-.567l-.619-.011c-.215 0-.563.08-.857.403-.295.322-1.121 1.094-1.121 2.674 0 1.58 1.122 3.108 1.278 3.323.158.215 2.207 3.383 5.351 4.753.748.323 1.33.515 1.782.659.748.238 1.43.204 1.969.124.601-.09 1.91-.779 2.179-1.531.268-.753.268-1.399.187-1.53-.075-.13-.29-.207-.612-.367z" />
      </svg>
    </a>
  );
}

export default WhatsAppButton;
