import React from "react";
import { Box } from "@mui/material";

import NavBar from "./components/NavBar";
import PageContent from "./components/PageContent";
import Footer from "./components/Footer";
import { INavigationLink } from "../../types/nav";
import WhatsAppButton from "./components/WhatsAppButton";

interface NavigationTemplateProps extends React.PropsWithChildren {
  links: INavigationLink[];
  buttonNav?: string; // Novo campo para o link do botão header
  buttonFooter?: string; // Novo campo para o link do botão footer
  linkWhatsApp?: string; // Novo campo para o link do botão
  hideFreeTrialButton?: boolean;
  hideFreeTrialButtonNav?: boolean;
  hideTranslateButton?: boolean;
  desktopLogo?: string;
  mobileLogo?: string;
}

export default function NavigationTemplate({
  links,
  buttonNav,
  children,
  buttonFooter,
  linkWhatsApp,
  hideFreeTrialButton,
  hideFreeTrialButtonNav,
  hideTranslateButton,
  desktopLogo,
  mobileLogo
}: NavigationTemplateProps) {
  return (
    <Box component="main" className="min-h-screen">
      <NavBar
        links={links}
        buttonNav={buttonNav}
        hideFreeTrialButtonNav={hideFreeTrialButtonNav}
        hideTranslateButton={hideTranslateButton}
        desktopLogo={desktopLogo}
        mobileLogo={mobileLogo}
      />
      <PageContent component="article" children={children} />
      <Footer
        links={links}
        buttonFooter={buttonFooter}
        hideFreeTrialButton={hideFreeTrialButton}
      />
      <WhatsAppButton linkWhatsApp={linkWhatsApp} />
    </Box>
  );
}
