/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { LocationDescriptor } from "history";
import { useHistory } from "react-router-dom";

import LoaderScreen from "../../LoaderScreen";
import SolutionsSection from "./components/SolutionsSection";
import ClientSection from "./components/ClientSection";
import PurposeSection from "./components/PurposeSection";
import SubscriptionSection from "./components/SubscriptionSection";
import FAQSection from "./components/FAQSection";

import { fetchWebSiteContent, webSiteInfo } from "../../../payload/data";
import PartnerLandscape from "../../../components/PartnerLandscape";
import { addPageConfigI18nTranslations, overridePageConfig } from "../../../payload/partner";
import { IPageConfigResponseModel } from "../../../types/api";
import NavigationTemplate from "../../../templates/NavigationTemplate";
import { createNavigationLinks } from "../../../payload/navigation";

export default function VestiWebsite() {
  const [content, setContent] = React.useState<IPageConfigResponseModel | null>(null);
  const history = useHistory<
    | {
        config?: IPageConfigResponseModel | undefined;
        message?: string | undefined;
      }
    | undefined
  >();

  const fetchVestiWebSiteContent = () => {
    fetchWebSiteContent(webSiteInfo.vesti.id)
      .then(data => {
        setContent(overridePageConfig(data, webSiteInfo.vesti.id));
        addPageConfigI18nTranslations(data.translations);
      })
      .catch(error => {
        let to: LocationDescriptor<{ message: string }> | null = null;

        if (typeof error === "string")
          to = {
            pathname: "/error",
            state: {
              message: error
            }
          };
        else
          to = {
            pathname: "/error",
            state: {
              message: "Falha na conexão"
            }
          };

        history.replace(to);
      });
  };

  React.useEffect(() => {
    const { state } = history.location;

    if (state && state.config) {
      setContent(state.config);
      addPageConfigI18nTranslations(state.config.translations);
    } else {
      fetchVestiWebSiteContent();
    }
  }, []);

  if (!content) {
    return <LoaderScreen logoUrl={webSiteInfo.vesti.loadingImage} />; // LOADING DO CLIENTE
  }

  const partnerConfig = content.partner!.vesti!;

  // console.log("partnerConfig", partnerConfig);

  return (
    <NavigationTemplate
      links={createNavigationLinks(webSiteInfo.vesti.path, {
        clientsLinkVisible: content.client.companies.length > 0
      })}
      buttonNav="https://register.bizpik.com.br/company?affiliationCode=AixGySbAkGxBvTGBDefV&plan=START&partner=vesti" // link para o register no botão testar gratis no header
      buttonFooter="https://register.bizpik.com.br/company?affiliationCode=AixGySbAkGxBvTGBDefV&plan=START&partner=vesti" // link para o register no botão testar gratis no footer
    >
      <PartnerLandscape
        partnerName={partnerConfig.name}
        partnerBannerUrl={partnerConfig.bannerImage}
        partnerLogoUrl={partnerConfig.logoImage}
        colors={partnerConfig.colors}
        comments={content.comments}
        backgroundImageUrl="https://bizpik-website.s3.us-east-1.amazonaws.com/assets/partners/vesti/capa.svg"
        textSocial="#58379F"
        subtitleText="Assistente do Vendedor: Conectando Marcas e Multimarcas com Agilidade e Inteligência!"
        childrenProps={{
          bannerImg: { sx: { width: "200%", position: "absolute", bottom: 0 } }
        }}
        origins={content.origins}
      />
      <SolutionsSection
        origins={content.origins}
        plugin={partnerConfig.section.solutions.plugin}
        aiCRM={content.solutions.aiCRM}
        aiCommerce={content.solutions.aiCommerce}
        aiChat={content.solutions.aiChat}
      />
      {content.client.companies.length > 0 && (
        <ClientSection companies={content.client.companies} title={content.client.title} />
      )}
      <PurposeSection />
      <SubscriptionSection
        partnerName={webSiteInfo.vesti.name}
        plans={partnerConfig.section.plans}
        plugin={partnerConfig.section.solutions.plugin}
      />
      <FAQSection />
    </NavigationTemplate>
  );
}
