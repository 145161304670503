import React from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  darken
} from "@mui/material";

import useAppFormatter from "../hooks/useAppFormatter";
import useAppTranslations from "../hooks/useAppTranslations";
import Translate from "./Translate";
import CheckBoxList from "./CheckBoxList";
import CheckBoxListItem from "./CheckBoxListItem";
// eslint-disable-next-line import/order
import { useLocation } from "react-router-dom";

interface PricingCardProps {
  title: string;
  text: string | React.ReactNode;
  lightColorTheme: string;
  features: string[];
  monthlyPrice: number | null;
  pluginFeatures?: string[];
  client?: string;
  obs?: {
    service: React.ReactNode;
    storage: React.ReactNode;
  };
  buttonHref?: string;
}

export default function PricingCard({
  features,
  pluginFeatures = [],
  lightColorTheme,
  monthlyPrice,
  title,
  text,
  client,
  buttonHref,
  obs
}: PricingCardProps) {
  const { t } = useAppTranslations();
  const { formatCurrency } = useAppFormatter();
  const { pathname } = useLocation();

  return (
    <Paper
      sx={theme => ({
        borderColor: lightColorTheme,
        minWidth: theme.spacing(56),
        [theme.breakpoints.down("sm")]: { minWidth: "100%" },
        [theme.breakpoints.down(40 * 8)]: { minWidth: theme.spacing(40) }
      })}
      className="border-solid border rounded-2xl p-8"
      elevation={0}
    >
      <Box className="">
        {client ? (
          <Typography
            className="font-bold border-b-4 pb-2 mb-8 uppercase truncate"
            fontSize={16}
            borderColor={lightColorTheme}
          >
            <Translate
              customKey="section:subs:card:title"
              values={{ plan: title.toUpperCase() }}
              components={{
                "1": (
                  <Typography
                    variant="caption"
                    component="span"
                    color={lightColorTheme}
                    sx={{
                      fontWeight: 700,
                      fontSize: 24,
                      display: "inline",
                      lineHeight: 0.8,
                      marginRight: 0.5,
                      color: lightColorTheme
                    }}
                  />
                )
              }}
            />
          </Typography>
        ) : (
          <Typography
            className="font-bold border-b-4 pb-2 mb-8 uppercase truncate"
            fontSize={24}
            borderColor={lightColorTheme}
            color={lightColorTheme}
            children={title}
          />
        )}

        <Typography
          className="font-normal h-[14rem] text-start"
          fontSize={16}
          children={text}
        />

        <Box borderColor={lightColorTheme} className="border-l-4 border-solid pl-6 py-1 my-6">
          <Typography className="font-bold text-left" fontSize={32} variant="inherit">
            {monthlyPrice
              ? `${formatCurrency(monthlyPrice, "pt")}/${t("misc:month").toLowerCase()}`
              : t("misc:query")}
          </Typography>

          {client && (
            <Typography
              color="text.secondary"
              className="font-bold text-left"
              fontSize={24}
              variant="inherit"
            >
              <Translate
                customKey="section:subs:card:offer"
                values={{ client: client.toUpperCase() }}
              />
            </Typography>
          )}
        </Box>

        <CheckBoxList checkBoxcolor={lightColorTheme}>
          {features?.map(feature => (
            <CheckBoxListItem
              key={feature}
              visible={Boolean(feature)}
              label={<Translate customKey={feature} />}
            />
          ))}
        </CheckBoxList>

        {Boolean(pluginFeatures?.length && client) && (
          <>
            <Typography
              className="font-bold border-b-4 pb-2 mb-8 uppercase"
              fontSize={20}
              borderColor={lightColorTheme}
              color={lightColorTheme}
              children={<Translate customKey="section:subs:card:plugin" values={{ client }} />}
            />

            <CheckBoxList
              childrenProps={{ muiList: { sx: { height: "120px" } } }}
              checkBoxcolor={lightColorTheme}
            >
              {pluginFeatures?.map(feature => (
                <CheckBoxListItem
                  key={feature}
                  visible={Boolean(feature)}
                  label={<Translate customKey={feature} />}
                />
              ))}
            </CheckBoxList>
          </>
        )}

        <List disablePadding>
          <ListItem className="list-disc" dense disablePadding disableGutters>
            <Box
              className="w-1.5 h-1.5 mr-4 -mt-1 rounded-md"
              sx={{ backgroundColor: "text.primary" }}
            />

            <ListItemText
              primary={obs?.service}
              primaryTypographyProps={{
                fontSize: 14,
                lineHeight: "17px",
                color: "#000"
              }}
            />
          </ListItem>

          <ListItem className="list-disc" dense disablePadding disableGutters>
            <Box
              className="w-1.5 h-1.5 mr-4 -mt-1 rounded-md"
              sx={{ backgroundColor: "text.primary" }}
            />

            <ListItemText
              primary={obs?.storage}
              primaryTypographyProps={{
                fontSize: 14,
                lineHeight: "17px",
                color: "#000"
              }}
            />
          </ListItem>
        </List>

        <Button
          href={
            buttonHref ||
            `https://register.bizpik.com.br/company?plan=${title}&partner=${pathname.slice(1)}`
          }
          sx={{
            backgroundColor: lightColorTheme,
            textTransform: "none",
            lineHeight: "22px",
            fontSize: 18,

            ":hover": {
              backgroundColor: darken(lightColorTheme, 0.2)
            }
          }}
          fullWidth
          className="font-semibold p-4 px-6 mt-8"
          variant="contained"
        >
          <Box component="span">
            <Translate i18nKey="label:sub" />
          </Box>
        </Button>
      </Box>
    </Paper>
  );
}
